import { useSelector } from "react-redux";
import { uploadUrl } from "../api";
import { RootState } from "../store";
import { AuthState } from "../store/authSlice";

export type EnabledLanguage = "ge" | "en";
export type AppThemeType = "dark" | "light";
export const supportedLocales: EnabledLanguage[] = ["ge", "en"];
export const isSupportedLanguage = (lang: string | null) =>
  lang && supportedLocales.some((l) => l === lang);

export type TranslationsField<TFields> = Partial<
  Record<EnabledLanguage, Partial<TFields> | undefined>
>;
//
//
export const georgianFormatter = new Intl.NumberFormat("ka-GE", {
  // style: 'currency',
  currency: "GEL",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});
//
//

type CurrencyCode = "eur" | "usd" | "gel";

export const currencySymbol = (
  currency?: CurrencyCode | string | undefined
) => {
  const renderCurrency: Record<CurrencyCode, string> = {
    eur: "€",
    usd: "$",
    gel: "₾",
  };

  const currentCurrency = currency?.toLowerCase() as CurrencyCode;
  return renderCurrency[currentCurrency];
};
//
//
//urls
export const getProductEditUrl = (productID: string | number) =>
  `/admin/travel-product-edit/${productID}`;
//
//
export const numberWithSpaces = (value?: number | string) => {
  if (value) {
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  } else {
    return value;
  }
};
//
// for permisions
export const Permision = (acceptedRoles?: string[] | []) => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const currentUerType = auth?.user?.userType;
  //
  const currentUerRoles = auth?.user?.companyUsers?.map((x) => x.role);
  //
  const allRols = currentUerRoles
    ? [...currentUerRoles, currentUerType]
    : [currentUerType];
  //
  const filteredRols = acceptedRoles?.filter(function (accepted_role) {
    return (
      allRols?.filter(function (current_user_roles) {
        return current_user_roles === accepted_role;
      }).length !== 0
    );
  });
  //
  //
  return filteredRols;
  //
};
//
//
// user roles
export enum UserRole {
  Frani_Admin = "Frani-Admin",
  Frani_Product_Owner = "Frani-Product-Owner",
  Insurance_Admin = "Insurance-Admin",
  Insurance_Company_Editor = "Insurance-Company-Editor",
  Insurance_Financial_Viewer = "Insurance-Financial-Viewer",
  Insurance_Product_Editor = "Insurance-Product-Editor",
  User = "User",
}
//
//
export enum AllAdminRoles {
  Frani_Admin = "Frani-Admin",
  Frani_Product_Owner = "Frani-Product-Owner",
  Insurance_Admin = "Insurance-Admin",
  Insurance_Company_Editor = "Insurance-Company-Editor",
  Insurance_Financial_Viewer = "Insurance-Financial-Viewer",
  Insurance_Product_Editor = "Insurance-Product-Editor",
}
//
//
export enum FraniAdminRoles {
  Frani_Admin = "Frani-Admin",
  Frani_Product_Owner = "Frani-Product-Owner",
}
//
//
export enum AdminRole {
  Insurance_Admin = "Insurance-Admin",
  Insurance_Product_Editor = "Insurance-Product-Editor",
  Insurance_Company_Editor = "Insurance-Company-Editor",
  Insurance_Financial_Viewer = "Insurance-Financial-Viewer",
}
//
export type AdminDeactivePolicyReason = {
  id: number;
  productCategoryId: number;
  translations: TranslationsField<{
    title: string;
  }>;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  isActive: boolean;
};
//
export type AdminInformationSheet = {
  id: number;
  productCategoryId: number;
  attachmentSlug: string;
  attachment: TranslationsField<{
    url: string;
  }>;
  createdAt: Date;
  updatedAt: Date;
};
//
export type AdminPaymentScheduleType = {
  id: number;
  number: number;
  slug: string;
  translations: TranslationsField<{
    title: string;
    description: string;
  }>;
  isActive: boolean;
  createdAt: Date;
};

export type AdminSoldPolicyFilterFields = {
  reportType: string;
  reportDownload: boolean;
  page: number | string;
  productCategory: "TRAVEL" | "TPL" | string;
  firstName: string;
  lastName: string;
  personalNumber: string;
  companyIds: number[];
  registerDateFrom: string;
  registerDateTo: string;
  paymentDateFrom: string;
  paymentDateTo: string;
  // policyStatus: "ACTIVE" | "PENDING" | "CLOSED" | "TESTING" | string;
  policyStatus: string[] | [];
  paymentStatus: "PAID" | "UNPAID" | string;
  policyNumber: string;
  tplRegistrationNumber: string;
  policyDateFrom: string;
  accountId: string;
  orderId: string;
};

//
export type AdminTravelPolicyDetail = {
  area: string;
  refundAmount: string;
  citizenship: string;
  createdAt: string;
  eachPrice: string;
  gender: string;
  id: number;
  insuranceDays: number;
  mainPolicy: PolicyDetailType;
  passportNumber: string;
  personalNumber: string;
  travelMultiDay: number;
  travelPolicyAttachedAddons: TravelPolicyAttachedAddons[];
  travelPolicyAttachedAddonsJson: TravelPolicyAttachedAddonsJson[];
  travelType: string;
  updatedAt: string;
  productCategoryId: number;
  attachedAddons: {
    price: string;
    title: string;
  }[];
  grouppedAddons: {
    price: string;
    title: string;
  }[];
  price?: number;
};
//
export type TravelPolicyAttachedAddons = {
  addonId: number;
  addonPrice: string;
  addonSlug: string;
  travelAttachedAddonId: number;
};
//
export type ProductCategoryEmail = {
  id: number;
  productCategoryId: number;
  title: string;
  emails: string[];
  createdAt: string;
  updatedAt: string;
};
//
export type TravelPolicyAttachedAddonsJson = {
  addonId: number;
  addonPrice: string;
  addonSlug: string;
  travelAttachedAddonId: number;
};
//
export type SoldPoliciesSearchKeywords = {
  pageNumber: number;
  personalNumber?: string;
  productCategory?: string;
  firstName?: string;
  lastName?: string;
  companyIds?: string;
};
//
export type SoldPolicies = {
  url: string;
  data: CompanyPolicyDetail[];
  meta: Pagination;
};
//
export type GeneratePolicyFilesProps = {
  policyType: string;
  policyIds: number[];
};
//
export type GeneratePolicyFilesType = {
  key: string;
  url: string;
  contentType: string;
  createdAt: string;
  updatedAt: string;
  id: number;
};
//
export type Pagination = {
  current_page: number;
  first_page: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  per_page: number;
  previous_page_url: string;
  total: number;
};
//
export type TplPolicyRegistration = {
  forSelf: boolean;
  paymentTerm: string;
  tplPackageId: number;
  compensationLimit: number;
  compensationCurrency: string;
  price: number;
  startDate: string;
  endDate: string;
  carInfo: {
    registerNumber: string;
    techPassportNumber: string;
    vinCode: string;
    carType: string;
    brand: string;
    model: string;
    yearOfManufacture: string;
    engineCapacity: string;
  };
  clientInfo: ClientInfo;
  carOwnerInfo: CarOwnerInfo;
};
//
export type ClientInfo = {
  franiTerms: FraniTerms;
  citizenship: string;
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  birthday: string;
  personalNumber: string;
  gender: string;
  phone: string;
  smsCode: number;
  email: string;
  password?: string;
};
//
export type CarOwnerInfo = {
  citizenship: string;
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  birthday: string;
  personalNumber: string;
  gender: string;
  phone: string;
  smsCode: number;
  email: string;
  idCardFiles: string[];
  driversLicenseFiles: string[];
  password?: string;
};
//
export type TplPersonalInfo = {
  cityzenship: string;
  personalNumber: string;
  passportNumber?: string;
  dateOfBirth: Date | null;
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  gender: string;
  idCardFrontSide: string;
  idCardBackSide: string;
  driverLicenseFrontSide: string;
  driverLicenseBackSide: string;
};
//
export type FaqTypes = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  cover: string;
  image: string;
  url: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  children: FaqTypes[];
};
//
export type AboutUsType = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  cover: string;
  image: string;
  url: string;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
  children: OurTeam[];
};
//
export type OurTeam = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  image: string;
  url: string;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
};
//
export type Footer = {
  title: string;
  children: FooterSubMenu[];
};
//
export type FooterSubMenu = {
  id: number;
  menuId: number;
  parentId: number;
  sort: number;
  url: string;
  createdAt: string;
  updatedAt: string;
  title: string;
};
//
export type MainPageText = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  cover: string;
  image: string;
  url: string;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
    body: string;
  }>;
  title: string;
  excerpt: string;
  body: string;
  createdAt: string;
  updatedAt: string;
};
//
export type TplPolicyRegisrationFirstStep = {
  id: number;
  listId: number;
  sort: number;
  type: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
};
//
export type Transation = {
  insuranceType: string;
  companyName: string;
  insuredName: string;
  policyNumber: string;
  paidAt: string;
  amount: string;
  transactionId: string;
  status: string;
};
//
export type TravelDateText = {
  type: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  title: string;
  body: string;
};
//
export type WebPage = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  cover: string;
  image: string;
  url: string;
  translations: TranslationsField<{
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  children: any[]; //TODO
  body: string;
};
//
export type TravelPagesWording = {
  id: number;
  listId: number;
  sort: number;
  type: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    excerpt: string;
    secondTitle: string;
    thirdTitle: string;
    secondExcerpt: string;
    fourthTitle: string;
    thirdExcerpt: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
//
export type AdminCompany = {
  id: number;
  sort: number;
  slug: string;
  cover: string;
  poster: string;
  commission: number;
  identificationNumber: string;
  phoneNumber: string;
  emailAddress: string;
  url: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  brandName: TranslationsField<{
    title: string;
  }>;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
    body: string;
    logo: string;
  }>;
  title: string;
  excerpt: string;
  body: string;
  logo: string;
};
//
export type AdminWebDocument = {
  id: number;
  parentId: number;
  sort: number;
  slug: string;
  cover: string;
  image: string;
  url: string;
  version: number;
  hide: boolean;
  translations: TranslationsField<{
    body: string;
  }>;
  body: string;
};
//
export type AdminMenu = {
  id: number;
  url: string | null;
  hide: boolean;
  permissions: string[] | [];
  translations: TranslationsField<{
    title: string;
  }>;
  sub_menu: AdminSubMenu[] | [];
  parentTranslation?: TranslationsField<{
    title: string;
  }>;
};
//
export type AdminSubMenu = {
  parentTranslation?: TranslationsField<{
    title: string;
  }>;
  sub_menu_id: number;
  url: string | null;
  hide: boolean;
  permissions: string[] | [];
  translations: TranslationsField<{
    title: string;
  }>;
};
//
export type AdminCompanyUser = {
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  password: string;
  companyUsers: UserType[];
};
//
export type Role = {
  id: number;
  userId: number;
  role: string;
  createdAt: string;
  updatedAt: string;
};
//
export type AddUser = {
  companyId: number;
  companyRoles: string[];
  firstName: string;
  lastName: string;
  email: string;
};
//
export type AdminAuthorizedCompany = {
  id: number;
  sort: number;
  slug: string;
  poster: string;
  cover: string;
  commission: number;
  identificationNumber: string;
  phoneNumber: string;
  emailAddress: string;
  url: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
  logo: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    excerpt: string;
    logo: string;
  }>;
};
//
export type TravelAddon = {
  id: number;
  productCategoryId: number;
  slug: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  title: string;
  body: string;
  createdAt: string;
  updatedAt: string;
};
//
//
export type AddTravelRisk = {
  icon: string;
  translations: TranslationsField<{
    riskName: string;
  }>;
};
//
export type TravelRisk = {
  id: number;
  icon: string;
  createdAt: string;
  updatedAt: string;
  translations: TranslationsField<{
    riskName: string;
  }>;
  riskName: string;
};
/** Many-to-many Package-TravelRisk pivot table */
export type TravelEnabledRisk = {
  id: number;
  packageId: number;
  travelRiskId: number;
  translations: TranslationsField<{
    riskDescription: string;
  }>;
  risk: TravelRisk;
};
//
export type TplOfferRequest = {
  whoChoseCompany: string;
  userChoseCompanies?: number[];
  paymentTerm: string;
  policyPeriod: {
    start: string;
    end: string;
  };
  carCharacteristics: {
    type: string;
    releaseYear: number;
  };
  selectedPackage: {
    currency: string;
    limit: number;
  };
};
//
export type TplOfferType = {
  companyId: number;
  companyName: string;
  compensationLimit: number;
  companyLogo: string;
  tplPackageId: number;
  productWording: string;
  maxAuthorizedDrivers: number;
  insuranceConditionsCovered: InsuranceConditionsCovered[];
  insuranceConditionsNotCovered: InsuranceConditionsNotCovered[];
  tplPackagePrice: TplPackagePriceType;
};
//
export type TplPackagePriceType = {
  id: number;
  tplPackageId: number;
  currency: string;
  premium: number;
  createdAt: string;
  updatedAt: string;
};
export type InsuranceConditionsNotCovered = {
  type: string;
  title: string;
};
//
export type InsuranceConditionsCovered = {
  type: string;
  title: string;
};
//
export type CarPropertiesType = {
  registerNumber?: string;
  techPassportNumber?: string;
  vinCode?: string;
  carType?: string;
  brend?: string;
  model?: string;
  yearOfManufacture?: string;
  engineCapacity?: string;
};
//
export type TplCurrencyList = {
  id: number;
  listId: number;
  sort: number;
  type: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
  secondTitle: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
//
export type TplInsurancePeriod = {
  id: number;
  slug: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    secondTitle: string;
    secondBody: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  secondTitle: string;
  secondBody: string;
};
//
export type TplStartDateText = {
  id: number;
  listId: number;
  sort: null | number;
  type: string;
  image: string | null;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
};
//
export type TplAndLocalTplList = {
  type: string;
  image: string;
  title: string;
  body: string;
};
//
export type PolicyRegistrationFirstStepList = {
  id: number;
  listId: number;
  sort: number;
  type: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
};
//
export type TravelPolicyRegistrationResponse = {
  userId: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
  id: number;
};
//
export type AdminPaymentListFilterFields = {
  page: number;
  limit: number;
  paymentDateFrom: string | null;
  paymentDateTo: string | null;
  companyId?: number;
  privateNumber: string | null;
  orderId: string | null;
  productCategoryId: string | null;
};
//
export type CompanyPaymentsListResponse = {
  meta: {
    total: number;
  };
  data: CompanyPaymentList[];
};
//
export type CompanyPaymentList = {
  fullName: string;
  personalNumber: string;
  orderId: number;
  policyNumber: string;
  policyPrice: number;
  totalCommission: number;
  currentCommission: number | undefined;
  franiPercent: number;
  paymentDate: string;
  paymentAmount: number;
  scheduledPaymentDate: string | undefined;
  scheduledPayment: number | undefined;
  paymentStatus: string;
};
//
export type CompanyPolicyDetail = {
  id: number;
  userId: number;
  packageId: number;
  policyNumber: string;
  policyPdfUrl: string;
  targetId: number;
  targetType: string;
  cardNumber: string | number;
  birthday: string;
  compensationLimit: number;
  compensationCurrency: string;
  franchise: number;
  status: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  companyId: number;
  package: UserPackage;
  orderItems?: CompanyOrderItems[];
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
};
//
export type TravelPolicyRegistration = {
  travelType: string;
  area: string;
  compensationLimit: number;
  uniqueId: string;
  compensationCurrency: string;
  insuranceType: string;
  startDate: string;
  endDate: string;
  travelPackageId: number;
  insurerInfo: InsurerInfo;
  travelersInfo: TravelersInfo[];
  travelMultiDay?: number | null;
  franchise: number;
  gpihApiData?: GpihApiDataType;
  aldagiApiData?: AldagiApiDataType;
};
//
export type CompanyPolicies = {
  id: number;
  citizenship: string;
  passportNumber: string;
  personalNumber: string;
  travelType: string;
  area: string;
  insuranceDays: number;
  travelMultiDay: number;
  eachPrice: number;
  gender: string;
  createdAt: string;
  updatedAt: string;
  mainPolicy: CompanyMainPolicy;
};
//
export type CompanyMainPolicy = {
  id: number;
  userId: number;
  packageId: number;
  policyNumber: string;
  policyPdfUrl: string;
  targetId: number;
  targetType: string;
  cardNumber: string;
  birthday: string;
  compensationLimit: number;
  compensationCurrency: string;
  franchise: number;
  status: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  orderItems: CompanyOrderItems[];
  firstName: string;
  lastName: string;
};
//
export type ApplyingDiscountDetails = {
  days: number;
  amount: number;
  percentageDiscount: {
    percentage: number;
    maximumAmount: number;
  };
  actualAmount: number;
  discountedAmount: number;
  saving: number;
};
//
export type CompanyOrderItems = {
  paymentScheduleType: any;
  slug: any;
  id: number;
  orderId: number;
  policyId: number;
  eachPrice: number;
  createdAt: string;
  updatedAt: string;
  order: {
    id: number;
    userId: number;
    totalPrice: number;
    paidAt: string | number;
    createdAt: string;
    updatedAt: string;
    discount: {
      promoCode: {
        code: string;
        id: number;
        name: string;
        applyingDiscount: ApplyingDiscountDetails;
      };
    };
    payments: Payment[];
  };
};
//
export type Payment = {
  id: number;
  orderId: number;
  amount: number;
  createdAt: Date;
  status: string;
  detailJson: PaymentDetail;
};
//
export type PaymentDetail = {
  pan: string;
  accountId: string;
};
//
export type Policies = {
  id: number;
  userId: number;
  travelType: string;
  area: string;
  compensationLimit: number;
  compensationCurrency: string;
  insuranceType: string;
  startDate: string;
  endDate: string;
  travelPackageId: number;
  insurerInfo: InsurerInfo;
  travelersInfo: TravelersInfo[];
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
};
//
export type UserPoliciesCardInfo = {
  mainPolicyId: number;
  insuranceType: string;
  companyName: string;
  policyNumber: string;
  insuredFullName: string;
  startDate: string;
  endDate: string;
  status: string;
};
//
export type UserCurrentPolicies = {
  id: number;
  citizenship: string;
  passportNumber: string;
  personalNumber: string;
  travelType: string;
  area: string;
  insuranceDays: number;
  travelMultiDay: number;
  eachPrice: number;
  gender: string;
  createdAt: string;
  updatedAt: string;
  mainPolicy: PolicyDetailType;
  attachedAddons: TravelAttachedAddon[];
};

//
export type UserTravelPolicyType = {
  data: PolicyDetailType[];
  meta: Pagination;
};
//
export type PolicyDetailType = {
  id: number;
  userId: number;
  packageId: number;
  policyNumber: string;
  policyPdfUrl: string;
  productWordingUrl: string;
  targetId: number;
  targetType: string;
  cardNumber: string;
  birthday: string;
  compensationLimit: number;
  compensationCurrency: string;
  franchise: number;
  status: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  firstName: string;
  lastName: string;
  personalNumber: string;
  package: UserPackage;
  sgsVerification: boolean;
  deactivatedPolicy: DeactivatedPolicy;
  orderItems: CompanyOrderItems[];
  tplCoverages: TplCoverage[];
  tplImportantTerms: TplImportantTerm[];
  translations?: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
};
//
export type DeactivatedPolicy = {
  cancellationPenalty: number;
  comment: string;
  createdAt: string;
  deactivatedDate: string;
  docUrl: string;
  earnedPremium: number;
  id: number;
  policyDeactivationReasonId: number;
  policyId: number;
  refundAmount: number;
  translations: TranslationsField<{
    title: string;
  }>;
};
//
export type UserPackage = {
  id: number;
  productId: number;
  packageId: number;
  targetId: number;
  targetType: string;
  compensationLimit: number;
  translations: TranslationsField<{
    comment: string;
  }>;
  discount: null; // ? ask irakli
  approvedByAdmin: boolean;
  status: string;
  activatedDate: string;
  deactivatedDate: string;
  createdAt: string;
  updatedAt: string;
  product: Product;
  compensationCurrencies: CompensationCurrencyValue[];
  franchises: number[];
  insuranceExceptions: InsuranceExceptions[];
  title: string;
  travelRisks: TravelRisk[];
  wordingFileUrl: string;
  welcomerEnabledRisks?: WelcomerEnabledRisk[];
  travelAttachedRisks?: TravelEnabledRisk[];
  target?: any;
};
//
export type InsuranceExceptions = {
  translations: TranslationsField<{
    title: string;
  }>;
};
//
export type NotCoveredRisks = {
  translations: TranslationsField<{
    title: string;
  }>;
};
//
export type TravelersInfo = {
  translations: TranslationsField<{
    firstName?: string;
    lastName?: string;
  }>;
  birthday: string;
  passportNumber: string;
  citizenship: string;
  personalNumber?: string;
  insuranceDays: number;
  gender: string;
  addons: number[] | [];
};
//
export type InsurerInfo = {
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  birthday: string;
  personalNumber: string;
  gender: string;
  email: string;
  password?: string;
  phone: string;
  smsCode: number;
  franiTerms: FraniTerms;
};
//
export type FraniTerms = {
  consentTerm: boolean;
  policyTerm: boolean;
  privacyTerm: boolean;
};
//
export type Product = {
  activatedDate: string;
  deactivatedDate: string;
  id: number;
  companyId: number;
  productCategoryId: number;
  targetId: number;
  targetType: string;
  slug: string;
  conditionFileUrl: string;
  wordingFileUrl: string;
  policyFileUrl: string;
  approvedByAdmin: boolean;
  status: string;
  integrated: boolean;
  commission: number;
  activationDate: Date | null;
  deactivationDate: Date | null;
  createdAt: string;
  updatedAt: string;
  emails: string[];
  title: string;
  body: string;
  company: Company;
  productCategory: ProductCategory;
  packages: Package[];
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  policyNumberRanges: PolicyNumberRange[];
};

export type PolicyNumberRange = {
  warningAlertRanges: number[];
  id: number;
  productId: number;
  policyPrefix?: string | null;
  policySuffix?: string | null;
  minPolicyNumber: string;
  maxPolicyNumber: string;
  usedPolicyNumber: number;
  product?: Product;
  createdAt: Date;
  updatedAt: Date;
};
/** TravelProduct day range (e.g. 1-180 day) for grouping prices */
export type TravelDayGroup = {
  minDay: number;
  maxDay: number;
};
export type TravelDayGroupWithCoefficient = TravelDayGroup & {
  coefficient?: number;
};
//
export type CompanyProductsWithSubModal = {
  id: number;
  companyId: number;
  productCategoryId: number;
  targetId: null;
  targetType: null;
  slug: string;
  conditionFileUrl: null;
  wordingFileUrl: null;
  policyFileUrl: null;
  approvedByAdmin: string;
  status: string;
  integrated: boolean;
  activationDate: null;
  deactivationDate: null;
  createdAt: string;
  updatedAt: string;
  packages: Package[];
  travelProducts: TravelProduct;
  travelPackages: TravelPackage;
};
//
export type Package = {
  id: number;
  productId: number;
  packageId: null;
  targetId: number;
  targetType: string;
  compensationLimit: number;
  discount: null;
  approvedByAdmin: string;
  status: string;
  activatedDate: null;
  deactivatedDate: null;
  createdAt: string;
  updatedAt: string;
  title: string;
  compensationCurrencies: CompensationCurrencyValue[];
  target?: TravelPackage | TplPackage | WelcomerPackage | PetPackage;
  translations: TranslationsField<{ title: string }>;
  franchises: number[];
  product: Product;
  insuranceExceptions: InsuranceException[] | null;
  travelRisks: TravelRisk[];
  /** @computed (generated) relationship data */
  travelAttachedRisks: TravelEnabledRisk[];
  conditionFileUrl: string;
  wordingFileUrl: string;
  policyFileUrl: string;
  tplInsurancePeriods?: TplInsurancePeriod[];
  tplPriceCombinations?: TplPriceCombination[];
  paymentScheduleTypeIds: number[] | null;
  wordingFiles: WordingFile;
  policyFiles: PolicyFiles[];
  welcomerEnabledRisks?: WelcomerEnabledRisk[];
  // policyFileUrl: string // the field exists for when we want separate PDF templates for each package (e.g. multi etc)
};
//
export type CompensationCurrencyValue = {
  currency: string;
};
//
export type WordingFile = {
  translations: TranslationsField<{
    url: string;
  }>;
};
//
export type PolicyFiles = {
  type: string;
  url: string;
};
//
export type InsuranceException = {
  translations: TranslationsField<{ title: string }>;
};
//
export type TravelRisks = {
  id: number;
  icon: null;
  createdAt: string;
  updatedAt: string;
  riskName: string;
};
//
export type AddonsInformation = {
  addonId: number;
  translations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
};
//
export type TravelGroupAttachedAddon = {
  id: number;
  productCategoryId: number;
  slug: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
};
//
export type groupedTravelAttachedAddon = {
  id: number;
  insuranceTranslations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
  translations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
  franchiseDescription: string;
  description: string;
  addon: TravelGroupAttachedAddon;
  addonId: number;
};
//
export type groupedTravelAttachedAddonInfos = {
  id: number;
  travelPackageId: number;
  paymentType: string;
  packageCurrency: string;
  premiumCurrency: string;
  premium: number;
  createdAt: string;
  updatedAt: string;
  addons: groupedTravelAttachedAddon[];

  groupedPackageCurrency: string;
  groupedPremiumCurrency: string;
  groupedPaymentType: string;
  groupedPremium: number;
  translations: TranslationsField<{
    title: string;
  }>;
  title: string;
  addonsInformation: AddonsInformation[];
};
//
export type NewTravelAttachedAddons = {
  addonId: number;
  paymentType: string;
  packageCurrency: string;
  premiumCurrency: string;
  premium: number | null;
  translations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
};
//
//
export type TravelPackage = {
  id: number;
  enableAddonsOnSingle: boolean;
  enableAddonsOnMulti: boolean;
  createdAt: string;
  updatedAt: string;
  travelAbroadPriceCombinations: TravelAbroadPriceCombination[];
  areas: Areas[];
  countryExceptionInfos: CountryExceptionInfo[];
  groupedTravelAttachedAddonInfos: groupedTravelAttachedAddonInfos[];
  travelAttachedAddons: NewTravelAttachedAddons[];
  mainPackage: Package;
  enableMulti: boolean;
  enableSingle: boolean;
  travelMultiDays: TravelMultiDayOption[];
};
//
export type CountryExceptionInfo = {
  countryISOCode: string;
};
export type Currency = "USD" | "EUR" | "GEL";
export const EnabledCurrencies: Currency[] = ["USD", "EUR", "GEL"];

export type TravelAbroadArea = "EUROPE" | "SCHENGEN" | "WORLD";
export const TravelAbroadAreaOptions: TravelAbroadArea[] = [
  "EUROPE",
  "SCHENGEN",
  "WORLD",
];

export type SingleMultiOption = "SINGLE" | "MULTI";
export const SingleMultiOptions: SingleMultiOption[] = ["SINGLE", "MULTI"];

export const TravelAbroadFranchiseOptions = [0, 50, 80, 150];

export type Areas = {
  area: string;
};
//
export type TravelAbroadPriceCombination = {
  id: number;
  /** attached to this Travel product */
  travelProductId: number;
  /** controls price on this package */
  travelPackageId: number;
  currency: string;
  franchise: number;
  minDay: number;
  maxDay: number;
  minAge: number;
  maxAge: number;
  area: string;
  travelType: string;
  premium: number;
  isAutoGenerated: boolean;
  createdAt: string;
  updatedAt: string;
};
//
export type TravelProduct = {
  id: number;
  mainProduct: Product;
  minimumInsuranceDays: number;
  maximumInsuranceDays: number;
  limitBeforeRealization: number;
  productMinPremium: number;
  singleTravelMinPremium: number;
  multiTravelMinPremium: number;
  createdAt: Date;
  updatedAt: Date;
  minTotalPremiumInfos: MinTotalPremiumAgeGroupWithCoefficient[]; // MinTotalPremiumByAgeInfos[]
  dayGroupInfos: TravelDayGroupWithCoefficient[];
  priceCombinations: TravelAbroadPriceCombination[];
  priceCoefficients: TravelProductPriceCoefficients;
  emails: string[];
  integrated: boolean;
};
//
// type AgeGroup = {
//   minAge: number
//   maxAge: number
// }
export type AgeGroupCoefficientByTravelArea = {
  area: TravelAbroadArea;
  coefficient: number;
};
export type MinTotalPremiumAgeGroupWithCoefficient =
  MinTotalPremiumByAgeInfos & {
    // coefficient?: number
    coefficientsByTravelArea?: AgeGroupCoefficientByTravelArea[];
  };
//
export type MinTotalPremiumByAgeInfos = {
  minAge: number;
  maxAge: number;
  minTotalPremium: number;
};
export type TravelProductPriceCoefficients = {
  basePrice: number;
  singlePolicyCoefficient: number;
  multiPolicyCoefficient: number;
  franchiseCoefficients: TravelProductFranchiseCoefficient[];
  areaCoefficients: TravelProductAreaCoefficient[];
  currencyCoefficients: TravelProductCurrencyCoefficient[];
  packageCoefficients: TravelProductPackageCoefficient[];
};
export type TravelProductFranchiseCoefficient = {
  franchise: number;
  coefficient: number;
};
export type TravelProductAreaCoefficient = {
  area: TravelAbroadArea;
  coefficient: number;
};
export type TravelProductCurrencyCoefficient = {
  currency: Currency;
  coefficient: number;
};
export type TravelProductPackageCoefficient = {
  packageId: number;
  coefficient: number;
};
//
export type AdminProductCategoriesList = {
  id: number;
  sort: number;
  slug: string;
  poster: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
};
//
export type AldagiApiDataType = {
  holders?: AldagiApiDataTypeHolders[];
};
//
export type GpihApiDataType = {
  resultHash?: string;
  holders?: GpihApiDataTypeHolders[];
};
//
export type AldagiApiDataTypeHolders = {
  travelerAge: number;
  calculatedPremiumByInsurancesDays: number;
};
//
export type GpihApiDataTypeHolders = {
  guid: string;
  travelerBirthday: string;
  calculatedPremiumByInsurancesDays: number;
};
//
// ?? OFFER TYPES ??
export type TravelAboardBestOfferResponse = {
  companyId: number;
  companyName: string;
  companyLogo: string;
  companySlug: string;
  productWording: string;
  productConditions: string;
  travelPackageId: number;
  travelRisks: TravelRisksType[];
  travelAttachedAddons: TravelAttachedAddon[];
  calculatedPrices: CalculatedPrices;
  notCoveredRisks: NotCoveredRisks[];
};
//
export type CalculatedPrices = {
  travelPackageId: number;
  selectedAddons: SelectedAddons[];
  totalPremium: number;
  totalPremiumWithAddons: number;
  onlyAddonsPremium: number;
  insurancesDays: number;
  travelType: string;
  area: string;
  franchise: number;
  compensationLimit: number;
  compensationCurrency: string;
  startDate: string;
  endDate: string;
  premiumByTravelers: PremiumByTravelers[];
  resultHash?: string;
};
//
export type SelectedAddons = {
  addonId: number;
  image: string;
  title: string;
  body: string;
  travelersCount: number;
};
//
export type PremiumByTravelers = {
  travelPackageId: number;
  travelerAge: number;
  minAge: number;
  maxAge: number;
  perDayPremium: number;
  calculatedPremiumByInsurancesDays: number; // lukam tqva dzmao
  travelerKey?: number;
  isLuggageInsured?: boolean;
  addonPrice: number;
  dayPremium: number;
  guid: string;
  travelerBirthday: string;
};
//
export type TravelAttachedAddon = {
  id: number;
  addonId: number;
  travelPackageId: number;
  paymentType: "ONCE" | "DAILY";
  premiumCurrency: Currency;
  premium: number;
  createdAt: string;
  updatedAt: string;
  addonInfos: AddonInfo[];
  body: string;
  price: string;
  title: string;
};
//
export type AddonInfo = {
  currency: string;
  /** this is a text comment field for this specific franchise */
  franchise: string;
  translations: TranslationsField<{ description: string }>;
  description: string;
};
// travel enabled risks returned in best offer
export type TravelRisksType = {
  travelRiskId: number;
  riskName: string;
  icon: string;
  createdAt: string;
  updatedAt: string;
  pivotData: {
    travelEnabledRiskId: number;
    travelRiskId: number;
    packageId: number;
    riskDescription: string;
  };
};
//
//
//
export type TravelAboardBestOfferPost = {
  categorySlug: string;
  whoChoseCompany: string;
  userChoseCompanies?: number[];
  selectedTravel: string;
  travelMultiDay?: number;
  travelDates: TravelSingleDates;
  travelArea: string;
  selectedPackage: SelectedPackage;
  travelersAge: number[];
  selectedCoverages: SelectedCoverages[];
  uniqueId: string;
  travelersBirthdays: TravelersBirthdays[];
};
//
export type TravelersBirthdays = {
  age: number;
  birthday: string;
};
//
//
export type TravelSingleDates = {
  start: string;
  end: string;
};
//
export type Franchise = {
  value: number;
  id: number;
};
//
export type SelectedPackage = {
  currency: string;
  limit: number;
  franchise: number;
};
//
export type SelectedCoverages = {
  id: number;
  travelersCount: number;
};
// ?? OFFER TYPES ??
//
//
//
export type BuyerInformationType = {
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  dateOfbirth: Date | null;
  idNumber: string;
  gender: string;
  email: string;
  passwod: string;
  phone: string;
  smsCode: number;
  cityzenship: string;
};
//
//
//
export type PersonalInfoExtraCoveragetype = {
  id?: number;
  title?: string;
};
export type ForMePersonalInfo = {
  cityzenship: string;
  passportNumber: string;
  idNumber: string;
  dateOfbirth: Date | null;
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
  }>;
  gender: string;
  extraCoverage: PersonalInfoExtraCoveragetype[];
  userTerms?: boolean;
  franiTerms?: boolean;
  insuranceCompanyTerms?: boolean;
};
export type TravelSingleOrMultiPrice = {
  type: string;
  singleInfo: {
    type: string;
    minPrice: number;
  };
  multiInfo: {
    type: string;
    minPrice: number;
  };
};
export type Country = {
  id: number;
  isoCode: string;
  createdAt: string;
  updatedAt: string;
  translations: TranslationsField<{
    name: string;
  }>;
  name: string;
};
export type travelersAgeType = {
  age: string;
};
export type selectedCurrencyLimitsAndFranchiseType = {
  currency: string;
  limit: number;
  franchise: any;
};
export type TravelSingleDateType = {
  start: Date;
  end: Date | null;
};
export type SelectedCompanyData = {
  id: number;
  title: string;
};
export type ExtraCoverageTypes = {
  id: number;
  travelersCount: number;
};
export type ExtraCoverages = {
  id: number;
  minPrice: number;
  image: string;
  title: string;
  body: string;
  children: [];
};
export type TravelFranchise = {
  value: number;
};
export type TravelLimits = {
  value: number;
};
export type TravelCurrencies = {
  type: string;
  image: string;
  title: string;
  excerpt: string;
  body: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
export type TravelRegions = {
  type: string;
  image: string;
  title: string;
  excerpt: string;
  body: string;
  secondTitle: string;
};
export type TravelMultiDayOption = {
  id: number;
  sort: number;
  days: number;
  insuredDays: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
    body: string;
  }>;
};
export type TravelProductsMinPrice = {
  type: string;
  productStartPrice: number;
  singleTravelStartPrice: number;
  multiTravelStartPrice: number;
};
export type InsuranceKinds = {
  image: string;
  title: string;
  body: string;
  type: string;
};
export type CompaniesWithProduct = {
  id: number;
  slug: string;
  logo: string;
  title: string;
  excerpt: string;
  body: string;
  type: string;
};
export type WhoWillChooseCompany = {
  image: string;
  title: string;
  body: string;
  type: string;
};
export type DepartureAndArrival = {
  image: string;
  title: string;
  body: string;
  type: string;
};
export type ProductCategory = {
  type: string;
  image: string;
  title: string;
  excerpt: string;
  body: string;
};
//
export type StoreFraniUser = {
  adminRole: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  id: number;
};
//
export type AdminTravelMultiDay = {
  id: number;
  sort: number;
  days: number;
  insuredDays: number;
  translations: TranslationsField<{
    title: string;
    excerpt: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
};
//
export type User = {
  activated: boolean;
  id: number;
  firstName: string;
  lastName: string;
  firstNameGe: string;
  lastNameGe: string;
  phone: string;
  personalNumber: string;
  age: string;
  avatar?: any;
  gender: string;
  emailVerification: boolean;
  identificated: boolean;
  enableWebNotifications: boolean;
  enableMobileNotifications: boolean;
  enableEmailNotifications: boolean;
  email: string;
  citizenship: string;
  passportNumber: string;
  birthday: string;
  lang: string;
  userType: "Frani-Admin" | "Frani-Product-Owner" | "User" | "Insurance";
  createdAt: string;
  updatedAt: string;
  companyUsers: UserType[];
  agreeToTerms: {
    consentTerm: boolean;
    policyTerm: boolean;
    privacyTerm: boolean;
  };
  roles: Role[];
};
//
export type UserType = {
  companyId: number;
  createdAt: string;
  id: number;
  role: string;
  updatedAt: string;
  userId: number;
};
//
export type ProfileEditableFields = {
  phone?: string;
  email?: string;
  age?: string;
  firstNameEn?: string;
  lastNameEn?: string;
  firstNameGe?: string;
  lastNameGe?: string;
  citizenship?: string;
  personalNumber?: string;
  passportNumber?: string;
  gender?: string;
  birthday?: string;
};
//
export type Company = {
  id: number;
  sort: number;
  slug: string;
  poster: string;
  logo: string;
  cover: string;
  commission: null;
  url: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  excerpt: string;
  body: string;
  brandName?: TranslationsField<{
    title: string;
  }>;
  translations: TranslationsField<{
    title: string;
  }>;
};
//
export type Notification = {
  notification: {
    userId: number;
    notificationType: string;
    translations: TranslationsField<{
      title: string;
      body: string;
    }>;
    createdAt: Date;
    updatedAt: Date;
    id: number;
    title: string;
    body: string;
  };
};
//
export type SignUpTypes = {
  firstName: string;
  lastName: string;
  phone: string;
  personalNumber: string;
  email: string;
  password: string;
  passwordConfirm: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  rememberMeToken: string;
};
//
export type SubNotification = {
  userId: number;
  device: string;
  token: {
    endpoint: string;
    keys: {
      p256dh: string;
      auth: string;
    };
  };
};
//
export type CreateNotification = {
  notificationType: string;
  technical: boolean;
  translations: TranslationsField<{
    title: string;
    message: string;
  }>;
};
//
export type NotificationList = {
  id: number;
  userId: number;
  notificationType: string;
  technical: boolean;
  translations: TranslationsField<{
    title: string;
    message: string;
  }>;
  readAt: null | Date;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  message: string;
};
//
export type WebMenu = {
  id: number;
  menuId: number;
  parentId?: any;
  sort: number;
  url: string;
  translations: TranslationsField<{
    title: string;
  }>;
  createdAt: string;
  updatedAt: string;
  subMenu: WebMenu[];
  title: string;
};
//
export type AdminCurrentUserCompany = {
  id: number;
  sort: number;
  slug: string;
  poster: string;
  logo: string;
  cover: string;
  commission?: any;
  url: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  excerpt: string;
  body: string;
};

//
export type WhatPeopleSay = {
  type: string;
  image: string;
  title: string;
  body: string;
};
//insurance user flow

//1st step
export type InsuranceForWho = {
  image: string;
  title: string;
  body: string;
  type: string;
};

//tpl
export type TplListItems = {
  body: string;
  createdAt: string;
  id: number;
  image: string;
  listId: number;
  sort: number;
  title: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    excerpt: string;
    secondTitle: string;
    thirdTitle: string;
    secondExcerpt: string;
    fourthTitle: string;
    thirdExcerpt: string;
  }>;
  type: string;
  updatedAt: string;
};

export type TplPagesWording = {
  createdAt: string;
  id: number;
  listItems: TplListItems[];
  slug: string;
  translations: TranslationsField<{
    title: string;
    stepName: string;
  }>;
  updatedAt: string;
};

export type TplLimitList = {
  id: number;
  value: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type TplCurrencies = {
  id: number;
  currencyCode: string;
  symbolOrName: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type PackageService = {
  id: number;
  slug: string;
  iconPath: string;
  sort: number | null;
  comment: string | null;
  translations: TranslationsField<{
    name: string;
    comment: string;
  }>;
  createdAt: Date;
  updatedAt: Date;
};
//tpl product
export type TplPolicyNumberRange = {
  id: number;
  productId: number;
  policyPrefix: string;
  minPolicyNumber: string;
  maxPolicyNumber: string;
  usedPolicyNumber: number;
  createdAt: string;
  updatedAt: string;
  policySuffix: string;
  warningAlertRanges: number[];
  numerationEmailSent: boolean;
};
export type TplInsuranceCondition = {
  type: string;
  translations: TranslationsField<{
    title: string;
  }>;
};
export type TplCarCategory = {
  id: number;
  slug: string;
  translations: TranslationsField<{
    title: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
};
export type TplMainProduct = {
  id: number;
  companyId: number;
  productCategoryId: number;
  targetId: number;
  targetType: string;
  slug: string;
  conditionFileUrl: string;
  wordingFileUrl: string;
  policyFileUrl: string;
  approvedByAdmin: boolean;
  status: string;
  integrated: boolean;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  activatedDate: string | null;
  deactivatedDate: string | null;
  createdAt: string;
  updatedAt: string;
  emailsJson: string[];
  commission: string;
  policyNumberRanges: PolicyNumberRange[];
  emails: string[];
  title: string;
  body: string;
};

export type TplProduct = {
  id: number;
  brandName: string;
  productMinPremium: string;
  maxAuthorizedDrivers: number;
  maxCarReleaseYear: number;
  paymentTermsJson: string[];
  insuranceConditionsJson: TplInsuranceCondition[];
  createdAt: string;
  updatedAt: string;
  tplCarCategories: TplCarCategory[];
  mainProduct: Product;
  paymentTerms: string[];
  insuranceConditions: TplInsuranceCondition[];
  productMarketingPrice: number;
  limitationOfStartDate: number;
  limitBeforeRealization: number;
};
export type TplPolicyRegistrationResponse = {
  userId: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
  id: number;
};

export type WordingFileType = {
  translations: TranslationsField<{ url: string }>;
};

export type PolicyFileType = {
  name?: string;
  type: "MONTHLY" | "ONE-TIME" | "MONTHLY-FIRST-TWO";
  url: string;
};

export type TplPriceCombination = {
  id: number;
  tplProductId: number;
  tplPackageId: number;
  currency: string;
  premium: string;
  createdAt: string;
  updatedAt: string;
};

export type TplPackage = {
  id: number;
  createdAt: string;
  updatedAt: string;
  mainPackage: Package;
  tplInsurancePeriods: TplInsurancePeriod[];
  tplPriceCombinations: TplPriceCombination[];
  compensationLimitsType: number;
  minAgeOfCarRelease: number;
  minAgeOfTheDriver: number;
  maxAgeOfTheDriver: number;
  destination: TranslationsField<{
    name: string;
  }>;
  priorityText: string;
  packageServiceRelations: PackageServiceRelation[];
  packageServiceIds?: number[];
  coveragesTranslationsArray: TplCoverageTranslations[];
  importantTermsTranslationsArray: TplImportantTermTranslations[];
  tplCoverages: TplCoverage[];
  carRegistrationCertificateDate: Date;
  tplImportantTerms: TplImportantTerm[];
};

export type PetPackage = {
  id: number;
  createdAt: string;
  updatedAt: string;
  mainPackage: Package;
  animalCategoryIds?: number[];
  exceptionBreedIds?: number[];
  packageServices: PetPackageService[];
  services: PetPackageServices[];
  maxAge: number;
  minAge: number;
  tplInsurancePeriods: TplInsurancePeriod[];
  tplPriceCombinations: TplPriceCombination[];
  compensationLimitsType: number;
  minAgeOfCarRelease: number;
  minAgeOfTheDriver: number;
  maxAgeOfTheDriver: number;
  destination: TranslationsField<{
    name: string;
  }>;
  priorityText: string;
  packageServiceRelations: PackageServiceRelation[];
  packageServiceIds?: number[];
  coveragesTranslationsArray: TplCoverageTranslations[];
  importantTermsTranslationsArray: TplImportantTermTranslations[];
  petCoverages: TplCoverage[];
  petImportantTerms: TplImportantTerm[];
};

export type PetPackageServices = {
  id: number;
  translations: TranslationsField<{
    comment: string;
  }>;
};
export type PetPackageService = {
  id: number;
  status: string;
  cover: string;
  translations: TranslationsField<{
    waitPeriod: string;
    limit: string;
  }>;
};
export type PackageServiceRelation = {
  id: number;
  tplPackageId: number;
  packageServiceId: number;
  createdAt: string;
  updatedAt: string;
  packageService: PackageService;
};
export type TplPackagePaymentScheduleTypes = {
  id: number;
  number: number;
  translations: TranslationsField<{
    title: string;
    description: string;
  }>;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type TplPackageLimit = {
  id: number;
  value: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};
export type TplPackageCurrency = {
  id: number;
  currencyCode: string;
  symbolOrName: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type TplPackagePrices = {
  id: number;
  packageId: number;
  limitId: number;
  currencyId: number;
  paymentScheduleTypeId: number;
  premium: number | null;
  createdAt: Date;
  updatedAt: Date;
  paymentScheduleType: TplPackagePaymentScheduleTypes;
  limit: TplPackageLimit;
  currency: TplPackageCurrency;
  tplPackage: TplPackage;
};

export type PetPackagePrices = {
  animalCategoryId: number;
  currency: string;
  id: number;
  limit: number;
  limitId: number;
  packageId: number;
  paymentScheduleTypeId: number;
  paymentScheduleType: TplPackagePaymentScheduleTypes;
  premium: number | null;
  createdAt: Date;
  updatedAt: Date;
  petPackage: PetPackage;
};
export type TplCoverageTranslations = {
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
};
export type TplImportantTermTranslations = {
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
};
export type TplCoverage = {
  createdAt: string;
  updatedAt: string;
  id: number;
  packageId: number;
  title: string;
  translations: TplCoverageTranslations;
};
//
export type TplImportantTerm = {
  createdAt: string;
  updatedAt: string;
  id: number;
  packageId: number;
  title: string;
  translations: TplImportantTermTranslations;
};
//
export type Covered = {
  travelRiskId: number;
  icon: string;
  translations: TranslationsField<{
    title: string;
    description: string;
  }>;
};
//
export type NotCovered = {
  id: number;
  translations: TranslationsField<{
    title: string;
    description: string;
  }>;
};
//
export type Risks = {
  translations: TranslationsField<{
    coveredTitle: string;
    notCoveredTitle: string;
  }>;
  covered: Partial<Covered>[];
  notCovered: NotCovered[];
};
//
export type AdminProductDetail = {
  id: number;
  translations: TranslationsField<{
    title: string;
    description: string;
    importantConditions: string;
  }>;
  type: string;
  photoUrl: string;
  fileKey: string;
  risks: Partial<Risks>;
  createdAt: Date;
  updatedAt: Date;
  companyProfile: TranslationsField<{
    title: string;
    description: string;
  }>;
};
//
export type AddWelcomerRisks = {
  icon: string;
  translations: TranslationsField<{
    riskName: string;
  }>;
};
//
export type WelcomerEnabledRisk = {
  id: number;
  packageId: number;
  riskId: number;
  translations: TranslationsField<{
    riskDescription: string;
  }>;
  risk: WelcomerRisk;
  welcomerRisk?: WelcomerRisk;
};
//
export type WelcomerLimitsList = {
  id: number;
  value: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};
//
export type WelcomerCurrencies = {
  id: number;
  currencyCode: string;
  symbolOrName: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};
//
export type WelcomerProductPriceCombination = {
  id: number;
  welcomerProductId: number;
  welcomerPackageId: number;
  currency: string;
  franchise: number;
  minDay: number;
  maxDay: number;
  minAge: number;
  maxAge: number;
  area: string;
  premium: number;
  isAutoGenerated: boolean;
  createdAt: string;
  updatedAt: string;
};
//
export type WelcomerProductPriceCoefficients = {
  basePrice: number;
  singlePolicyCoefficient: number;
  franchiseCoefficients: TravelProductFranchiseCoefficient[];
  currencyCoefficients: TravelProductCurrencyCoefficient[];
  packageCoefficients: TravelProductPackageCoefficient[];
};
//
export type WelcomerMinTotalPremiumAgeGroupWithCoefficient =
  MinTotalPremiumByAgeInfos & {
    // coefficient?: number
    coefficientsByWelcomerArea?: AgeGroupCoefficientByTravelArea[];
  };
//
export type WelcomerProduct = {
  id: number;
  mainProduct: Product;
  minimumInsuranceDays: number;
  maximumInsuranceDays: number;
  limitBeforeRealization: number;
  productMinPremium: number;
  singleMinPremium: number;
  createdAt: Date;
  updatedAt: Date;
  priceCombinations: WelcomerProductPriceCombination[];
  priceCoefficients: WelcomerProductPriceCoefficients;
  emails: string[];
  integrated: boolean;
  welcomerAgeGroup: WelcomerAgeGroup[];
  welcomerDayGroup: WelcomerDayGroup[];
};
//
export type WelcomerPackage = {
  id: number;
  enableAddonsOnSingle: boolean;
  enableAddonsOnMulti: boolean;
  createdAt: string;
  updatedAt: string;
  welcomerPriceCombination: TravelAbroadPriceCombination[];
  countryExceptionInfos: CountryExceptionInfo[];
  groupedAttachedAddonInfos: groupedWelcomerAttachedAddonInfos[];
  attachedAddons: NewWelcomerAttachedAddon[];
  mainPackage: Package;
  productId: number;
  limitId: number;
  currencyIds: number[] | undefined;
};
//
export type NewWelcomerAttachedAddon = {
  addonId: number;
  paymentType: string;
  packageCurrency: string;
  premiumCurrency: string;
  premium: number | null;
  translations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
};
//
export type groupedWelcomerAttachedAddonInfos = {
  id: number;
  welcomerPackageId: number;
  paymentType: string;
  packageCurrency: string;
  premiumCurrency: string;
  premium: number;
  createdAt: string;
  updatedAt: string;
  addons: groupedWelcomerAttachedAddon[];

  groupedPackageCurrency: string;
  groupedPremiumCurrency: string;
  groupedPaymentType: string;
  groupedPremium: number;
  translations: TranslationsField<{
    title: string;
  }>;
  title: string;
  addonsInformation: AddonsInformation[];
};
//
export type groupedWelcomerAttachedAddon = {
  id: number;
  addonId: number;
  groupedAttachedAddonInfoId: number;
  description: string;
  franchiseDescription: string;
  translations: TranslationsField<{
    description: string;
    franchiseDescription: string;
  }>;
};
//
export type WelcomerGroupAttachedAddon = {
  id: number;
  productCategoryId: number;
  slug: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
};
//
export type WelcomerPolicyRegistrationResponse = {
  userId: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
  id: number;
};
//
export type WelcomerRisk = {
  id: number;
  icon: string;
  createdAt: string;
  updatedAt: string;
  translations: TranslationsField<{
    riskName: string;
  }>;
  riskName: string;
};
//
export type WelcomerAddon = {
  id: number;
  productCategoryId: number;
  slug: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
  }>;
  title: string;
  body: string;
  createdAt: string;
  updatedAt: string;
};
//
export type WelcomerPagesWording = {
  createdAt: string;
  id: number;
  listItems: WelcomerListItems[];
  slug: string;
  translations: TranslationsField<{
    title: string;
    stepName: string;
  }>;
  updatedAt: string;
};
//
export type WelcomerListItems = {
  id: number;
  listId: number;
  sort: number;
  type: string;
  image: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    bodyTitle: string;
    stepName: string;
    bodyDescription: string;
  }>;
  createdAt: string;
  updatedAt: string;
  title: string;
  body: string;
  bodyTitle: string;
  stepName: string;
  bodyDescription: string;
};
//
export type WelcomerAgeGroup = {
  id: number;
  ageFrom: number;
  ageTo: number;
  minPremium: number;
};
//
export type WelcomerDayGroup = {
  id: number;
  minDay: number;
  maxDay: number;
  coefficient: number;
};
//
export type WelcomerPackagePrices = {
  id: number;
  packageId: number;
  limitId: number;
  currencyId: number;
  welcomerAgeGroupId: number;
  welcomerDayGroupId: number;
  premium: number | null;
  createdAt: Date;
  updatedAt: Date;
  limit: TplPackageLimit;
  currency: TplPackageCurrency;
  welcomerAgeGroup: WelcomerAgeGroup;
  welcomerDayGroup: WelcomerDayGroup;
  welcomerPackage: WelcomerPackage;
};
//
export type WelcomerPackagePricesList = {
  priceId: number;
  premium: number | null;
};
//
export type CorporateModuleDetails = {
  id: number;
  companyName: string;
  idNumber: string;
  activityField: string;
  comment: string;
  firstname: string;
  lastname: string;
  position: string;
  email: string;
  mobileNumber: string;
  type: string;
  employeesCount: number;
  insuredEmployeesCount: number;
  topManagementCount: number;
  averageAge: string;
  genderMale: string;
  genderFemale: string;
  minInsurancePackage: string;
  insurancePayer: string;
  usedInsuranceLastYear: boolean;
  insuranceCompanyName: string;
  contractRenewalDate: string;
  carBrand: string;
  carModel: string;
  manufactureYear: number;
  purpose: string;
  currency: string;
  marketValue: number;
  cadastralCode: string;
  propertyType: string;
  insurePropertyContents: boolean;
  address: string;
  insuranceProduct: string;
  createdAt: string;
  updatedAt: string;
};

export type CorporateModule = {
  data: CorporateModuleDetails[];
  totalCount: number;
};

export type TplDetailPageType = {
  refundAmount: string;
  personalNumber: string;
  id: number;
  citizenship: string;
  gender: string;
  price: number;
  paymentTerm: any;
  forSelf: boolean;
  idCardFiles: any;
  driversLicenseFiles: any;
  registerNumber: string;
  techPassportNumber: string;
  vinCode: string;
  carType: string;
  brand: string;
  model: string;
  yearOfManufacture: number;
  engineCapacity: string;
  createdAt: string;
  updatedAt: string;
  mainPolicy: PolicyDetailType;
  carRegistrationCertificateDate: Date;
};

export type PetDetailPageType = {
  animalCategory: PetCategory;
  breed: PetBreed;
  refundAmount: string;
  personalNumber: string;
  id: number;
  citizenship: string;
  gender: string;
  price: number;
  paymentTerm: any;
  createdAt: string;
  updatedAt: string;
  mainPolicy: PolicyDetailType;
  petInformation: {
    name: string;
    color: string;
    gender: string;
    age: {
      month: number;
      year: number;
    };
  };
};

//

export type TplMonthlyPaymentSchedule = {
  id: number;
  orderId: number;
  transactionId: string;
  amount: number;
  status: "init" | "paid" | "failed";
  createdAt: Date;
  updatedAt: Date;
  detailJson: {
    paymentDate: Date;
    transferAmount: number;
  };
  details: {
    scheduledPaymentDate: Date;
    paymentDate: Date;
    transferAmount: number;
  };
};

export type TplPaymentSchedule = {
  payments: TplMonthlyPaymentSchedule[];
  nextPaymentDate: Date;
  nextPaymentAmount: number;
  totalRemainedPaymentAmount: number;
  currency: string;
};

export type ScheduleType = {
  nextPaymentDate: Date;
  nextPaymentAmount: string | number;
  totalPaymentAmount: string | number;
  currency: string;
  schedule: {
    name: string;
    active: boolean;
    passed: boolean;
    amount: string | number;
  }[];
};

export type TplTransactions = {
  carRegistrationNumber: string;
  companyTranslations: TranslationsField<{
    title: string;
  }>;
  policyNumber: string;
  transferDate: Date;
  transferAmount: number;
  status: string;
};

export type PetCategory = {
  alias: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  sort: number;
  translations: TranslationsField<{
    name: string;
  }>;
};

export type PetBreed = {
  id?: number | null;
  categoryId: number;
  translations: TranslationsField<{
    name: string;
  }>;
  status: string;
};

export type PetService = {
  id: number;
  sort: number;
  priority: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  translations: TranslationsField<{
    name: string;
  }>;
};

export type FraniPartnerType = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  imageUrl: string;
  webAddress: string;
  translations: TranslationsField<{
    title: string;
    body: string;
    secondBody: string;
    thirdBody: string;
  }>;
};

export type ApplyingDiscount = {
  days: number;
  amount: number;
  percentageDiscount: {
    percentage: number;
    maximumAmount: number;
  };
};

export type ProductPromoCodes = {
  id: number;
  productId: number;
  promoCodeId: number;
};

export type PromoCodeOption = {
  policyInterval: {
    startDate: Date | string;
    endDate: Date | string;
  };
};

export type PromoCode = {
  key: number;
  id: number;
  name: string;
  translations: TranslationsField<{
    name: string;
  }>;
  code: string;
  capacity: number | null | undefined;
  remainedCapacity: number;
  usageAmount: number | undefined;
  applyingDiscount: ApplyingDiscount;
  status: string;
  startDate: Date | string;
  endDate: Date | string;
  activationDate: Date | string | null;
  imageUrl: string;
  productCategorySlugs: string[];
  userIds: number[];
  type: "amount" | "percentage" | "days";
  productPromoCodes: ProductPromoCodes[];
  options: PromoCodeOption;
  mustBeOwned: boolean;
  published: boolean;
};

export type WebUser = {
  id: number;
  firstName: string;
  lastName: string;
  firstNameGe: string;
  lastNameGe: string;
  email: string;
  phone: string;
  personalNumber: string;
  passportNumber: string;
  age: number;
  birthday: string;
  citizenship: string;
  gender: string;
  franiUser: boolean;
  activated: boolean;
  identificated: boolean;
  phoneVerification: boolean;
  emailVerification: boolean;
  sgsVerification: boolean;
  enableWebNotifications: boolean;
  enableMobileNotifications: boolean;
  enableEmailNotifications: boolean;
  lang: string;
  createdAt: string;
  updatedAt: string;
  userType: "Frani-Admin" | "Frani-Product-Owner" | "User" | "Insurance";
  companyUsers: UserType[];
  agreeToTerms: boolean;
  avatar: string;
};

export type WebUserList = {
  data: WebUser[];
  totalCount: number;
};

export type LandingSlide = {
  id: number;
  promoCodeId: number;
  published: boolean;
  translations: TranslationsField<{
    title: string;
    description: string;
    btnText: string;
  }>;
  photoUrl: string;
  fileKey: string;
  createdAt: string;
  updatedAt: string;
  productCategoryId: number;
  redirectUrl: string;
};

export type LandingSlideRoot = {
  data: LandingSlide[];
  totalCount: number;
};

export const avatarUrl = (user: User) =>
  user?.avatar ? uploadUrl(user.avatar) : undefined;
